/**
 * Данная функция принимает строку URL с приставкой языка и возвращает приставку языка
 * @param pathname - текущий полный путь
 * @returns Возвращает pathname без текущей локали - string
 */
export function removeLocaleFromPath(pathname: string) {
  const localeRegex = /^\/[a-zA-Z]{2}/;

  const urlWithoutLocale = pathname.replace(localeRegex, '');

  return urlWithoutLocale;
}
