import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"variable\":\"--font-rubik\",\"display\":\"swap\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Unbounded\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"variable\":\"--font-unbounded\",\"display\":\"swap\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"unbounded\"}");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"variable\":\"--font-montserrat\",\"display\":\"swap\",\"weight\":\"400\",\"style\":\"normal\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/code/shared/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["IsAuth"] */ "/code/widgets/IsAuth/IsAuth.tsx");
