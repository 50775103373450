/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable lines-around-directive */
/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { storeAdmin } from '@/shared/store/admin';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { AUTH_PATH, NOT_ACCESS_PATH, ROUTES } from '@/shared/config/routes';
import { useLocale } from 'next-intl';
import { removeLocaleFromPath } from '@/shared/lib/removeLocaleFromPath';

export const IsAuth = observer(() => {
  const router = useRouter();
  const locale = useLocale();
  const pathname = usePathname();

  useEffect(() => {
    const pathnameWithoutLocal = removeLocaleFromPath(pathname);

    if (storeAdmin.isVerifiedUser && AUTH_PATH.includes(pathnameWithoutLocal)) {
      router.push(`/${locale}${ROUTES.home}`);
    }

    if (
      !storeAdmin.isVerifiedUser &&
      !NOT_ACCESS_PATH.includes(pathnameWithoutLocal) &&
      !pathnameWithoutLocal.includes('create-password')
    ) {
      router.push(`/${locale}${ROUTES.signIn}`);
    }
  }, [storeAdmin.isVerifiedUser, pathname]);

  return <span style={{ display: 'none' }} />;
});
